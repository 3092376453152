<template>
  <div id="group-receive-setting" class="container">

    <div class="alert-warning rounded border-warning p-2 mb-4">
      <i class="fas fa-exclamation-circle"></i> ระบบจะยึดค่าในหน้านี้เพื่อกำหนดจำนวนรับของสูงสุดต่อตัวในงวดต่อไป หากต้องการตั้งค่ารับของเฉพาะงวด ท่านสามารถตั้งค่ารับของเฉพาะงวดได้ที่หน้า <router-link :to="{name: 'RoundSetting'}">ตั้งค่ารับของเฉพาะงวด</router-link> ได้เลย
    </div>

    <div class="page-header mb-1">
      <strong>ตั้งค่ารับของ</strong>
    </div>

    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="group in groups" :key="group._id" class="nav-item">
            <span class="nav-link" :class="[{'active':tabActiveGroup===group._id}]" @click="tabActiveGroupId=group._id">{{ group.groupTitle }}</span>
          </li>
        </ul>
      </div>

      <div class="p-2">
        <table class="table table-hover table-bordered mb-0">
          <thead>
            <tr>
              <th class="text-center" rowspan="2">หวย</th>
              <th width="5%" class="text-center">เลือก</th>
              <th width="10%" v-for="bet in marketOpenBets" :key="bet.code" class="text-center">{{ bet.text }}</th>
              <th width="6%" class="text-center">แก้ไข</th>
            </tr>
            <tr>
              <th class="text-center">
                <b-form-checkbox :disabled="!permSetting" v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
              </th>
              <th v-for="bet in marketOpenBets" :key="bet.code" class="text-center p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="inputBulk[bet.code]"
                  :disabled="!bulkMarketIds.length"
                >
                </b-form-input>
              </th>
              <th class="text-center p-1">
                <b-button variant="success" size="sm" :disabled="!bulkMarketIds.length || !permSetting" @click="saveBulk">บันทึก</b-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in markets"
              :key="item._id"
              :class="[{'alert-dark': item.marketType !== 'single'}]"
            >
              <td>
                <img v-if="item.imageIcon" :src="item.imageIcon" style="width: 30px; height: 18px;" class="mr-1">
                {{item.marketTitle}}
              </td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="bulkMarketIds"
                  :value="item._id"
                  :disabled="item.marketType !== 'single' || !permSetting"
                />
              </td>
              <td
                v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-right p-1 pr-2">{{ receiveSetting[item._id][bet.code] | numberFormat }}</td>
              <td class="text-center">
                <button :disabled="item.marketType !== 'single' || !permSetting" class="btn btn-sm btn-outline-primary" @click="editReceive(item._id)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!markets.length">
              <td :colspan="marketOpenBets.length + 3" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <MarketReceiveModal :data="editData" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

import MarketSettingService from "@/services/MarketSettingService"
import MarketReceiveModal from './components/MarketReceiveModal'

const inputBulk = {
  threeNumberTop: '',
  threeNumberTode: '',
  twoNumberTop: '',
  twoNumberBottom: '',
  runTop: '',
  runBottom: ''
}

export default {
  name: 'GroupReceive',
  components: {
    MarketReceiveModal
  },
  data() {
    return {
      tabActiveGroupId: null,
      checkAll: false,
      bulkAccountIds: [],
      inputBulk: JSON.parse(JSON.stringify(inputBulk)),
      bulkMarketIds: [],
      receiveSetting: null,
      editData: null,
      isShowModal: false,
      refSetting: {}
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups.filter((group)=>{
        return group.status === 'Active' && this.refSetting?.[group._id]?.isAvailable && group.groupType === 'single'
      })
      .map((group)=>{
        return {
          _id: group._id,
          groupTitle: group.groupTitle
        }
      })
    },
    tabActiveGroup() {
      return this.tabActiveGroupId
    },
    marketOpenBets() {
      return marketOpenBets
    },
    markets() {
      if(!this.receiveSetting)
        return []

      return this.$store.state.markets.filter(market => market.groupId === this.tabActiveGroup)
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.MarketReceive === 'w'
    }
  },
  watch: {
    tabActiveGroup() {
      this.resetBulk()
    },
    checkAll(n, o) {
      this.bulkMarketIds = []
      if(n){
        for(const item of this.markets) {
          if(item?.marketType === 'single') {
            this.bulkMarketIds.push(item._id)
          }
        }
      }
    },
  },
  methods: {
    loadReceiveSetting() {
      MarketSettingService.getMarketSettingReceive()
      .then((response)=>{
        if(response.success) {
          this.receiveSetting = response?.data?.settings ?? {}
          this.refSetting = response?.data?.refSetting ?? {}

          if(!this.tabActiveGroupId) {
            this.tabActiveGroupId = this.groups[0]?._id ?? null
          }
        }
      })
      .finally(()=>{

      })
    },
    saveBulk() {
      MarketSettingService.updateMarketSettingReceive({
        marketIds: this.bulkMarketIds,
        receive: this.inputBulk
      })
      .then((response)=>{
        if(response.success) {
          this.resetBulk()
          this.loadReceiveSetting()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    resetBulk() {
      this.checkAll = false
      this.inputBulk = JSON.parse(JSON.stringify(inputBulk))
      this.bulkMarketIds = []
    },
    editReceive(marketId) {
      console.log(this.receiveSetting[marketId])
      this.editData = {
        marketId: marketId,
        receive: this.receiveSetting[marketId]
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadReceiveSetting()
      }
    },
  },
  created() {
    this.tabActiveGroupId = this.groups[0]?._id

    if(this.groups.length === 0) {
      this.$store.dispatch('reloadMarketGroups')
    }
  },
  mounted() {
    this.loadReceiveSetting()
  },
  filters: {
    numberFormat(num) {
      if(!num)
        return 0

      return parseFloat(num).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-link {
  cursor: pointer;
}

.table {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        line-height: 1;
      }
    }
  }
}
</style>
